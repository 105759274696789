<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Редактировать сотрудника - {{userName}}</span>
                <el-button @click="close" class="right-button" type="danger" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form
            ref="htmlForm"
            :model="form"
            :rules="rules"
            label-position="top"
            class="form-max-width"
        >
            <el-row :gutter="15">
                <el-col :span="8" >
                    <el-form-item label="Фамилия" prop="last_name">
                        <el-input type="text" v-model="form.last_name" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Имя" prop="first_name">
                        <el-input type="text" v-model="form.first_name" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Отчество" prop="second_name">
                        <el-input type="text" v-model="form.second_name" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item label="Телефон" prop="phone">
                        <el-input type="text" placeholder="+7 (###) ###-##-##" v-maska data-maska="+7 (###) ###-##-##" v-model="form.phone" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Должность" prop="position">
                        <el-input type="text" v-model="form.position" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Город проживания" prop="city" class="field-phone-number first">
                        <el-input type="text" v-model="form.city" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item label="Статус" prop="status" class="field-phone-number first">
                        <el-select v-model="form.status" style="width: 100%;">
                            <el-option
                                v-for="status in data.statuses"
                                :key="status.key"
                                :label="status.name"
                                :value="status.key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Организации" prop="status" class="field-phone-number first">
                        <el-select multiple v-model="form.companies" style="width: 100%;">
                            <el-option
                                v-for="company in companies"
                                :key="company.id"
                                :label="company.name"
                                :value="company.id"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item label="Пароль" prop="password">
                        <el-input type="password" v-model="form.password" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Повторить пароль" prop="confirm_password">
                        <el-input type="password" v-model="form.confirm_password" />
                    </el-form-item>
                </el-col>
            </el-row>

            <template v-for="(item,index) in form.contact" :key="index">
                <el-row :gutter="15">
                    <el-col :span="8">
                        <el-form-item :class="['field-phone-number']" label="Тип контакта">
                            <el-select v-model="item.type" style="width: calc(100% - 61px);margin-right: 15px;">
                                <el-option
                                    v-for="type in item.types"
                                    :key="type.value"
                                    :label="type.label"
                                    :value="type.value"
                                />
                            </el-select>
                            <el-button type="danger" @click="removeContact(index)" icon="Close"></el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Контакт">
                            <template v-if="item.type === 'phone'">
                                <el-input placeholder="+7 (###) ###-##-##" @blur="blurContact" v-maska data-maska="+7 (###) ###-##-##" v-model="item.value" />
                            </template>
                            <template v-else>
                                <el-input v-model="item.value" />
                            </template>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Примечание">
                            <el-input v-model="item.note" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </template>

            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item>
                        <el-button style="width: 100%;" @click="addContact" icon="Check" class="button-add-number">Добавить дополнительный контакат</el-button>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item>
                <el-button class="button-max-width color-taxi" @click="submit">
                    Редактировать сотрудника
                </el-button>
            </el-form-item>
        </el-form>
    </el-card>
</template>

<script setup>
import {inject, reactive, ref} from "vue";
import { vMaska } from "maska"
import {useRoute, useRouter} from "vue-router";

const tx = inject('tx')
const route = useRoute()
const router = useRouter()
const userName = ref('')
const companies = ref([])
const htmlForm = ref(null)
const data = reactive({
    statuses : [
        {key : 'work', name : 'Работает'},
        {key : 'holiday', name : 'Отпуск/Больничный'},
        {key : 'fired', name : 'Уволен'},
    ]
})
const form =  reactive({
    first_name      : '',
    last_name       : '',
    second_name     : '',
    phone           : '',
    position        : '',
    city            : '',
    status          : '',
    contact         : [],
    contact_remove  : [],
    companies       : [],
    password        : null,
    confirm_password: null,
})

tx.service.query.v1.get('/companies/' + route.params.company_id + '/employees/' + route.params.employee_id).then(res => {
    tx.service.query.v1.get('/companies').then(companiesResponse => {
        companies.value = companiesResponse;

        userName.value = res.name

        const names = res.name.split(' ')

        form.first_name      = names[1]
        form.last_name       = names[0]
        form.second_name     = names[2]
        form.phone           = res.phone
        form.position        = res.position
        form.city            = res.city
        form.status          = res.status
        form.contact         = res.contacts.map(el => {
            return {
                types  : [{value: 'phone', label: 'Телефон'}, {value: 'email', label: 'Email'}],
                type   : el.type,
                value  : el.contact,
                note   : el.note,
                id     : el.id,
            }
        })
        form.contact_remove  = []
        form.companies       = res.companies.map(el => el.id)
    })
})

const confirmPassword = (rule, value, callback) => {
    if (value !== form.password)
        return callback(new Error("Пароли не совпадают!"))
    else
        return callback()
}

const rules = reactive({
    first_name: {required: true, message: 'Поле "Имя" обязательно для заполнения', trigger: 'blur'},
    last_name: {required: true, message: 'Поле "Фамилия" обязательно для заполнения', trigger: 'blur'},
    position: {required: true, message: 'Поле "Должность" обязательно для заполнения', trigger: 'blur'},
    city: {required: true, message: 'Поле "Город проживания" обязательно для заполнения', trigger: 'blur'},
    password: {min: 8, message: 'Поле "Пароль" должно быть не менее 8 символов', trigger: 'blur'},
    confirm_password: {validator: confirmPassword, trigger: 'blur'},
    phone: [
        {required: true, message: 'Поле "Телефон" обязательно для заполнения', trigger: 'blur'},
        {min: 18, max: 18, message: 'Поле "Телефон" указано некорректно', trigger: 'blur'}
    ],
})

const addContact = function(){
    form.contact.push({
        types   : [{value : 'phone',label : 'Телефон'},{value : 'email',label : 'Email'}],
        type    : 'phone',
        contact : '',
        note    : '',
    })
}

const removeContact = function(key){
    form.contact = form.contact.filter((el,index) => {
        if(key != index)
            return true
        else{
            if('id' in el)
                form.contact_remove.push(el.id)
            return false
        }
    });
}

const blurContact = function(){
    form.contact.forEach(el => {
        if(el.type === 'phone' && el.value.length < 18)
            el.value = ''
    })
}

const submit = function(){
    htmlForm.value.validate((isValid) => {
        if(isValid){

            let body = {};
            body.fio                        = form.last_name + ' ' + form.first_name + ' ' + form.second_name
            body.position                   = form.position
            body.phone                      = form.phone
            body.city                       = form.city
            body.status                     = form.status
            body.contact_remove             = form.contact_remove
            body.companies                  = form.companies
            if(form.password) {
                body.password = form.password
                body.password_confirmation = form.confirm_password
            }
            body.contacts = form.contact.filter(el => el.value).map(el => {
                let obj = {};
                obj['value'] = el.value
                obj['type']  = el.type
                obj['note']  = el.note
                obj['id']    = ('id' in el) ? el.id : 0;
                return obj;
            });

            tx.service.query.v1.put('/companies/'+route.params.company_id + '/employees/' + route.params.employee_id,body).then(() => {
                close()
                tx.service.successEdit()
            })
        }
    })
}

const close = () => {
    router.push('/companies/' + route.params.company_id + '/employees')
}

</script>

<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Редактировать авто - {{autoName}}</span>
                <el-button @click="close" type="danger" class="right-button" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form
            ref="htmlForm"
            :model="form"
            :rules="rules"
            label-position="top"
        >
            <el-row :gutter="15">
                <el-col :span="8" v-if="form.brand !== null">
                    <el-form-item label="Марка" prop="brand">
                        <el-input type="text" v-model="form.brand" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="form.model !== null">
                    <el-form-item label="Модель" prop="model">
                        <el-input type="text" v-model="form.model" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="form.year !== null">
                    <el-form-item label="Год выпуска" prop="year">
                        <el-input type="text" v-maska data-maska="####" v-model="form.year" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="form.number !== null">
                    <el-form-item label="Гос номер (РУССКИЕ БУКВЫ)" prop="number">
                        <!-- через | задается еще 1 токен, на случай если будет нужна маска по конкретным буквам -->
                        <el-input type="text" placeholder="A 000 AA 00X" v-maska:[optionsMaska] v-model="form.number"/>
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="form.color !== null">
                    <el-form-item label="Цвет" prop="color">
                        <el-input type="text" v-model="form.color" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="form.status !== null">
                    <el-form-item label="Статус" prop="status" class="field-phone-number first">
                        <el-select v-model="form.status" style="width: 100%;">
                            <el-option
                                v-for="status in statuses"
                                :key="status.key"
                                :label="status.name"
                                :value="status.key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="form.active !== null">
                    <el-form-item label="Активность" >
                        <el-switch v-model="form.active" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item>
                        <el-button class="button-max-width color-taxi" @click="submit">
                            Редактировать авто
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</template>

<script setup>
import {inject, reactive, ref} from "vue";
import { vMaska } from "maska"
import {useRoute, useRouter} from "vue-router";

const route = useRoute()
const router = useRouter()
const tx = inject('tx')
const props    = defineProps({
    companies: Object
})
const emit     = defineEmits(['closed'])
const htmlForm = ref(null)
const autoName = ref('')
tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + + route.params.auto_id).then(auto => {
    autoName.value = (auto.brand || '') + ' ' + (auto.model || '')
    form.brand     = (auto.brand || null)
    form.model     = (auto.model || null)
    form.year      = (auto.year || null)
    form.number    = (auto.number || null)
    form.status    = (auto.status || null)
    form.color     = auto.color //TODO Надо проверить будет ли работать для остальных полей
    form.active    = 'active' in auto ? !!auto.active : null
})

const optionsMaska = reactive({
    mask: "U ### UU ##0",
    tokens : {
        0 : { pattern : /[0-9]/,optional : true},
        U : {pattern : /[АВСЕНКМОРТХУ]/, transform: (chr) => chr.toUpperCase()}
    }
})

const statuses = [
    {key : 'work', name : 'Работает'},
    {key : 'dtp', name : 'ДТП'},
]
const form =  reactive({
    brand     : null,
    model     : null,
    year      : null,
    number    : null,
    status    : null,
    active    : null,
})

const rules = reactive({
    brand: {required: true, message: 'Поле "Марка" обязательно для заполнения', trigger: 'blur'},
    model: {required: true, message: 'Поле "Модель" обязательно для заполнения', trigger: 'blur'},
    status: {required: true, message: 'Поле "Статус" обязательно для заполнения', trigger: 'blur'},
    year: [
        {required: true, message: 'Поле "Год выпуска" обязательно для заполнения', trigger: 'blur'},
        {min: 4, max: 4, message: 'Поле "Год выпуска" должно состоять из 4 символов', trigger: 'blur'},
    ],
    number: [
        {required: true, message: 'Поле "Гос номер" обязательно для заполнения', trigger: 'blur'},
        {min: 11, max: 12, message: 'Поле "Гос номер" должно быть указано в формате A 000 AA 00X', trigger: 'blur'}
    ],
})

const submit = function(){
    htmlForm.value.validate((isValid) => {
        if(isValid){
            tx.service.query.v1.put('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id, form).then(() => {
                tx.service.successEdit()
                close()
            })
        }
    })
}
const close = () => {
    router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos')
}

</script>
